@import "~materialize-css/sass/components/color-variables";

$color-placeholder: #00000055;
$theme-color: var(--theme--Color);
$primary-text-color: var(--primary-text--Color);
$color-red: $theme-color;
$color-red-darker: $theme-color;
$color-grey: #d9d9d9;
$color-darkgrey: #1f1f1f;
$color-green: #82b150;
$color-green-darker: #628147;
$color-white: #FFFFFF;
$color-black: #000000;
$warning-color: $color-red;

$input-focus-color: $color-red;
$radio-border: 2px solid $color-green;

@import "~materialize-css/sass/materialize";
@import "scrollbar";
@import "main-page";
@import "~react-toastify/dist/ReactToastify.min.css";
@import '~dragula/dist/dragula.min.css';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';
@import '~react-circular-progressbar/dist/styles.css';

.color-green {
  background-color: $color-green;
}

html {
  background: #d9d9d9;
  height: auto !important;
}

.error {
  border-bottom: 1px solid #ee3f43 !important;
}

.card-name-error {
  border-bottom: 1px solid #ee3f43 !important;
  box-shadow: 0 1px 0 0 #ee3f43 !important;
}

.card-name-valid {
  border-bottom: 1px solid $color-green !important;
  box-shadow: 0 1px 0 0 $color-green !important;
}

html,
body,
#app,
#app>div {
  height: 100%
}

body {
  font-size: 14px;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, sans-serif;
}

.container {
  width: 100%;
  max-width: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.card-title {
  font-size: 1.4em !important;
}

#root {
  background-color: $color-grey;
  min-height: 100%;
}

.top-header-card {
  margin: 20px 30px;
}

.unread {
  background-color: $color-green;
}

.loginPaneLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-grey;
  min-height: 100vh !important;
  ;
  height: auto;
  width: 100%;
  padding: 0;
}

.loginPaneRight {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
  min-height: 85vh !important;
  height: auto;
  width: 100%;
  padding: 0;
  float: none !important;
  margin: auto;
  position: relative;
}

.upkeepMediaLogoTagLine {
  width: 26% !important;
}

.loginPaneRight .select-wrapper {
  border-radius: 5px !important;
  width: 87% !important;
  height: 50px;
  margin: auto !important;
  padding: 5px 10px !important;
  color: white;
  background: #eeeeee;
}

.loginPaneRight a {
  color: white;
  border-bottom: 1px solid white;
}

.nav-wrapper {
  background-color: $color-red;
}

#response-rate-table, #customer-tabs-container {
  padding: 18px;
  margin-top: 22px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #929daf;
  text-transform: uppercase;
}

.profile-image {
  vertical-align: middle;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 4px
}

.round-button {
  border-radius: 5px !important;
  width: 80% !important;
  height: 45px;
  margin: auto !important;
  padding: 5px 10px !important;
}

.logo-box{
  text-align: center;
}

.logo-box h5{
  color: #444;
  margin-bottom: 40px;
}

.border-text-box {
  border-radius: 5px !important;
  padding: 5px 10px !important;
  background-color: #eeeeee !important;
  width: 80% !important;
  margin: auto !important;
}

.border-text-box::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-placeholder;
  opacity: 1;
  /* Firefox */
}

.loginPaneRight .border-text-box, .login-button.round-button, .loginPaneRight .select-wrapper{
  box-sizing: border-box !important;
  width: 100% !important;
}

.loginPaneRight .select-wrapper + label{
  position: relative;
  top: -5px;
  color: #FFF;
  transform: none;
  -webkit-transform: none;
  left: 0;
}

.loginPaneRight .flipLabelTitle{
  width: 100%;
  display: inline-block;
}

.loginPaneRight .flipLabelTitle .input-field{
  display: inline-flex;
  flex-direction: column-reverse;
}
.border-text-box:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-placeholder;
}

.recaptcha-box div {
  display: flex;
}

.alignLabel {
  width: 100%;
  text-align: left;
}

.outer-login-container {
  width: 100%;
  margin: auto;
  display: inline-block;
  background-color: $color-darkgrey;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px 10px;
}

.outer-login-container.vipleadmachine {
  background-color: $primary-text-color;
}

.inner-login-container {
  width: 100%;
  max-width: 90%;
  margin: auto;
}

.border-text-box::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $color-placeholder;
}

.progress {
  background-color: $color-red;
}

.progress .indeterminate {
  background-color: $color-red-darker;
}

.listing_button .input-field.col {
  width: 82%;
  margin-right: 16px;
}

.listing_button {
  display: flex;
  align-items: center;
}


.listing_button .btn-side button {
  margin-right: 6px;
}

.show-done-video-link {
  margin-right: 15px;
}

.listing_button1 .input-field.col {
  width: 77%;
  margin-right: 16px;
}

.listing_button1 {
  display: inline-block;
  align-items: center;
}

.listing_button1 .btn-side1 button {
  margin-top: -3px;
  margin-right: 12px;
}

.btn-side {
  margin-left: auto;
}

.user-card-title {
  border-bottom: 1px solid lightgray;
  padding: 24px;
}

.cards-panel {
  overflow-x: scroll;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cards-panel>tbody>tr {
  vertical-align: top;
  border: none;
}

.cards-panel>tbody>tr>td {
  vertical-align: top;
  border: none;
}

.Toastify__progress-bar--default {
  background: $color-red;
}

.btn:hover {
  background-color: $color-green-darker;
}


.btn-danger:hover {
  background-color: $color-red-darker;
}

.btn-primary {
  background-color: $color-green;
  border-radius: 5px;
}

.btn-secondary {
  background-color: $color-grey;
  border-radius: 5px;
}

.btn-danger {
  background-color: $color-red;
  border-radius: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.comment-tag {
  color: #ee3f43;
}

.m_box_me {
  text-align: right;
}

.m_box {
  text-align: left;
}

.chat_bubble {
  display: block;
  max-width: 80%;
  word-break: break-word;
}

.yt-Helper-HideResponseRateTab .tab:nth-child(2) {
  display: none;
}

.user-tabs .tab:nth-child(4) {
  display: none;
}

.chat_bubble a {
  font-weight: bold;
  color: royalblue;
}

.dropdown-content li>a,
.dropdown-content li>span {
  color: $color-darkgrey;
}

.basic-single {
  margin-left: 36px;
}

.basic-single .select__input input {
  height: auto;
}

#viewCardModal h4 {
  font-size: 20px;
  text-transform: none;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 0 0 16px;
  margin: 0 0 20px;
  width: 90%;
}

#viewCardModal .right-align button {
  margin: 0 0 0 14px;
}

#viewCardModal .right-align button.btn.btn-secondary:hover {
  color: #fff;
}

#viewCardModal .right-align button.btn.btn-secondary {
  color: #000;
}

.ModalDiv1 {
  margin: 10px 0 0;
  padding: 0 0 13px;
}

.ModalDiv1 .row {
  margin: 0 0 10px;
}

.ModalDiv1 strong.blue-text {
  font-weight: normal;
  font-size: 16px;
  padding-left: 16px;
  margin-right: 1px;
}

.ModalDiv1 .col {
  position: relative;
}

.ModalDiv1 .col svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.row.assigned {
  margin: 20px 0 0 !important;
}

.assigned .left-padding {
  padding-left: 26px;
  position: relative;
}

.assigned .left-padding svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px !important;
  width: 20px !important;
}

.select_dropdown .basic-single {
  margin: 0;
}

.select_dropdown .basic-single .select__control {
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  height: auto;
}

.date_feature button {
  margin-left: 0 !important;
  padding: 12px 16px !important;
  height: auto !important;
  line-height: normal;
}

.date_feature button i {
  margin: 0;
}

.date_feature .react-datetime-picker__wrapper {
  border: 1px solid #ccc;
  padding: 0;
}

.linking_area .padding-left {
  padding-left: 0;
  position: relative;
}

.linking_area .row.middle_row {
  float: left;
  margin: 10px 0 12px;
  width: 100%;
}

.linking_area .row.middle_row .col.s12.m4 {
  padding-left: 0;
}

.bottom_row .col.s12.m4 {
  padding-left: 0;
}

.row.bottom_row.margin_bottom {
  margin-bottom: 0;
}

#viewCardModal {
  width: 70%;
  max-height: 97%;
}

h5.video_heading {
  margin: 0 0 22px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

.outer_video_row .col.s12 {
  display: flex;
  flex-wrap: nowrap;
}

.outer_video_row .col.s12 #preview-vid, #done-video-preview-2 {
  flex: 0 0 60%;
  margin-right: 26px;
  width: 60% !important;
  max-width: 60%;
  border: none !important;
  border-radius: 0 !important;
}

.outer_video_row .col.s12 .row.chatbox {
  margin: 0;
  overflow-y: inherit !important;
  overflow-x: inherit !important;
  position: relative;
}

.outer_video_row .col.s12 .row.chatbox .row.align-left {
  background: #f5f5f5;
  padding: 11px;
  border: 1px solid #ccc;
  margin: 21px 0 !important;
  width: 100%;
  position: relative;
}

.outer_video_row .col.s12 .row.chatbox .row.align-left .m_box p {
  margin: 0;
}

.outer_video_row .col.s12 .row.chatbox .row.align-left .m_box {
  padding-left: 18px;
}

.row.bottom_row {
  float: left;
  width: 100%;
  margin: 0;
}

.row.comment_area button i {
  margin: 0;
}

.common_chat {
  background: #f5f5f5;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 0 0 10px 0 !important;
  position: relative;
}

.annotation-colored-circle {
  // display: inline-block;
  margin-left: 5px;
  margin-right: -9px;
  margin-top: -8px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: green;
  height: 10px;
  width: 10px;
  float: right;
  background-color: green;
}

.annotation-label-colored-circle {
  // display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: green;
  height: 10px;
  width: 10px;
  float: left;
  background-color: green;
}

.yt-chat-colored-circle {
  // display: inline-block;
  margin-left: 5px;
  margin-right: -9px;
  margin-top: -8px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: blue;
  height: 10px;
  width: 10px;
  float: right;
  background-color: blue;
}

.yt-chat-label-colored-circle {
  // display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  border-color: blue;
  height: 10px;
  width: 10px;
  float: left;
  background-color: blue;
}

.col.chat-select {
  width: 85%;
  margin-right: 0px;
}

.col.filter-chat-select {
  width: 77%;
  margin-right: 10px;
}

.mark-comment-btn {
  position: absolute;
  right: 15px !important;
  // top: 50%;
  // transform: translateY(-50%);
}

.mark-comment-btn button.btn {
  width: 50px;
  text-align: center;
}
.chat-loading {
  fill: $theme-color !important;
  height: auto !important;
  width: auto !important;
  svg {
    width: 100% !important;
    height: 30px !important;
  }
}
.modal-content .row.linking_area .col .mark-comment-btn button.btn>svg {
  width: 18px !important;
  height: 18px !important;
  margin-right: 0 !important;
  position: relative !important;
  top: 0 !important;
}

// .mark-comment {
//   position: absolute;
//   right: 30px !important;
//   // top: 50%;
//   // transform: translateY(-50%);
// }

.mark-comment .fa-2x {
  font-size: 1.5em !important;
}

.row.comment_area {
  margin: 0;
  text-align: left;
}

.common_chat p.chat_bubble {
  text-align: left;
  max-width: inherit !important;
  margin: 0;
}

.common_chat .text-left {
  text-align: left;
}

.comment_area .row.text-area_chat {
  position: relative;
  margin-bottom: 0px;
}

span.helper-text {
  display: none !important;
}

.submit_chat {
  position: absolute;
  right: 0 !important;
  top: 0;
  left: auto !important;
}

.outer_video_row .col.s12 .row.chatbox {
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 0 0 40%;
  max-width: 40%;
  margin-left: 5px;
}

#viewCardModal .modal-footer {
  position: absolute;
  top: 0;
  background: none;
  width: auto;
  right: 30px;
  top: 12px;
}

.linking_area .col.s12.m4 {
  padding-left: 0;
}

.outer_video_row .col.s12 .row.chatbox h5 {
  margin: 6px 0 0;
}

.outer_video_row .row.chatbox .row.comment_area .col.s2 button.btn-large.btn-flat i {
  margin: 0;
}

.outer_video_row .row.chatbox .row.comment_area .col.s2 button.btn-large.btn-flat {
  padding: 0 13px;
  text-align: center;
}

.outer_video_row .row.chatbox .row.comment_area .col.s2 button.btn-large.btn-flat:hover i {
  color: #fff;
}

textarea.materialize-textarea {
  font-size: 12px !important;
  width: 90% !important;

}

.custom-input-profile textarea.materialize-textarea {
  padding-top: 2rem;
  min-height: 4rem;
}

.difplay_flext {
  display: block;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.search_bar_top {
  position: relative;
}

.search_bar_top .input-field.col {
  margin: 0;
  padding: 0;
}

.search_bar_top button.btn {
  height: 66px;
  margin-left: 25px;
  border-radius: 6px;
}

.search_bar_top input {
  margin: 0 !important;
  background: #fff !important;
  padding: 25px 10px !important;
  height: auto !important;
  border: none !important;
  width: 100% !important;
  border-radius: 6px !important;
}

.editor_text strong {
  font-weight: bold;
}

.editor-icon {
  margin-top: 10px;
}

.editor_text ul:not(.browser-default)>li {
  list-style-type: disc !important;
  margin-left: 45px;
}

.rdw-editor-main {
  height: 200px !important;
}

.editor_text li {
  margin: 0 !important;
}

.editor_text ul,
.editor_text ol {
  padding: 0 0 0 20px;
  margin: 15px 0 0;
}

#update-card-modal {
  width: 70% !important;
}

.outer_video_row textarea {
  width: 77% !important
}

/*sid*/
#user_cards .container .card {
  box-shadow: 0 0 13px #848282;
  border-radius: 8px !important;
  padding-bottom: 12px;
}

#user_cards .container .card button.btn.btn-danger {
  width: auto !important;
  padding: 22px 28px 22px 42px;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
}

#user_cards .container .card button.btn.btn-danger i {
  top: 50%;
  right: auto;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
}

#user_cards .container .card .card-action {
  padding-bottom: 4px;
}

#user_cards .card-content {
  padding-bottom: 0;
  padding-top: 10px;
}

#user_cards {
  .user-type-dropdown {
    float: left;
  }
  i.material-icons.user-type-Icon  {
    float: left;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}

.modal-content h4 {
  text-align: left;
  margin: 0 0 20px;
  text-transform: uppercase;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

#addUserModal .input-field i {
  font-size: 23px;
  text-align: center;
  margin: 2px 0 0;
  color: #7e2c2a;
}

#addUserModal .input-field button.btn-flat {
  padding: 0;
  text-align: center;
  margin: -6px 0 0;
}

#addUserModal .container .row:last-child {
  margin-bottom: 0;
}

#addUserModal .modal-footer {
  text-align: center;
  margin: 0 0 18px;
}

#addUserModal .modal-footer button {
  padding: 22px 28px;
  height: auto;
}

#addUserModal .modal-content {
  padding-right: 20px;
}

#user_profile_page {
  margin: 35px 25px 35px;
  max-width: 1400px;
  padding: 18px;
  border-radius: 7px;
  box-shadow: 0 0 17px #5f5d5d;
}

#user_profile_page .input-field .materialize-textarea {
  // height:5rem;
  margin-top: 15px;
}

#user_profile_page .input-field label.active {
  margin-bottom: 15px;
  display: block;
}

#user_profile_page .card-action {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-top: 34px;
}

#user_profile_page .card-action button {
  width: auto !important;
  position: relative;
  height: auto;
  padding: 25px 22px 25px 50px;
  border-radius: 5px;
  color: #fff;
}

#user_profile_page .card-action button i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#user_profile_page .card-content .input-field i {
  font-size: 22px;
}

#user_profile_page .card-content h4 {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

#userUpdateConfirmation .modal-content {
  text-align: center;
}

#userUpdateConfirmation .modal-content h4 {
  text-align: center;
}

#userUpdateConfirmation .modal-footer button {
  width: auto !important;
  height: auto;
  padding: 18px 22px;
}

#viewCardModal .inside-row {
  margin: 0 -15px;
}

.inside_linkg {
  border-bottom: 1px solid transparent;
  display: flex;
  padding: 15px 0;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.thumbnail_row {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.thumbnails_link_div {
  width: 50%;
}

.thumbnails-file-col {
  width: 50%;
}

.thumbnail-file-wrap img {
  width: 300px !important;
}

.thumbnail-file-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumbnail-prev-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.thumbnail-prev-btn .btn {
  margin: 10px;
}
.thumbnails_link_div .input-field.col.s12 {
  width: 100%;
}
.description_wrap .input-field.col {
  width: 100%;
  padding: 0;
}
.thumbnails-file-wrap .input-field.col.file-field {
  width: 100%;
}

.chatbox {
  position: relative;
}

.chatbox h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.text-area_chat .input-field {
  float: none;
}

.text-area_chat .input-field textarea {
  margin-bottom: 0;
}

.comment_area .col.submit_chat button.btn-large.btn-flat {
  height: 45px;
  line-height: 45px;
}
.comment_area .col.submit_chat button.btn-large.btn-flat:hover{
  color: #FFF
}

.inside_linkg .dropdown {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin: 0 0 15px;
}

.date_feature .react-datetime-picker__wrapper input {
  margin-bottom: 0;
  border: none;
  padding: 0 3px;
}

.date_feature button.btn-large.center-align.btn-primary {
  margin: -5px 12px 0 0 !important;
}

.date_feature .react-datetime-picker__wrapper {
  border-radius: 4px;
}

.last-rev {
  margin-top: 18px;
}

@media (max-width:767px) {
  #update-card-modal {
    width: 90% !important;
  }

  #viewCardModal {
    width: 90% !important;
  }

  span.red {
    width: 80%;
  }

  #viewCardModal h4 {
    font-size: 15px;
    text-align: center;
    margin: 0;
  }

  #viewCardModal .modal-footer {
    right: 0;
    top: -14px !important;
    height: auto !important;
    padding: 0 !important;
  }

  #viewCardModal .modal-footer button {
    font-size: 0;
    margin: 13px 9px 0 0;
    padding: 0;
    text-align: center;
  }

  .linking_area audio {
    width: 100%;
  }

  #viewCardModal .modal-footer button i {
    margin-left: 0;
  }

  .assigned .left-padding {
    margin: 0 0 6px 0;
  }

  .row.assigned {
    margin: 32px 0 0 !important;
  }

  .linking_area .col.s12.m4 {
    padding-left: 0;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }

  .chatbox h5,
  h5.video_heading {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .chatbox svg {
    width: 15px;
    float: left;
    margin: -4px 4px 0 0 !important;
  }

  .chatbox .row {
    margin: 0 0 10px 0 !important;
  }

  .comment_area .row.text-area_chat .input-field {
    padding: 0;
  }

  .comment_area .row.text-area_chat textarea {
    width: 75% !important;
  }

  .submit_chat button i {
    font-size: 16px;
  }

  .chatbox .row.comment_area {
    padding: 0 !important;
  }

  .submit_chat button {
    padding: 0;
  }

  .outer_video_row .col.s12 {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .outer_video_row .col.s12 #preview-vid, #done-video-preview-2 {
    flex: 0 0 100%;
    margin-right: 0;
    width: 10% !important;
    max-width: 100%;
    border: none !important;
    border-radius: 0 !important;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .outer_video_row .col.s12 .row.chatbox {
    margin: 0;
    // height: 437px;
    height: 150px;
    //   overflow-y: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
  }

  // .outer_video_row .row.chatbox .row .col.chat-annotation-label
  // {
  //   width:100%;
  //   position: absolute;
  //   top: 45px;
  //   left: 0;
  //   right: 0;
  //   text-align:center;
  // }


  .outer_video_row .col.s12 .row.chatbox .row.align-left .m_box {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }

  .common_chat>.col {
    padding: 0;
    margin: 0 0 8px;
  }

  .multibutton {
    display: flex;
    flex-wrap: wrap;
  }

  #viewCardModal .multibutton button {
    margin: 0 6px 8px !important;
    padding: 0 8px;
    font-size: 12px;
  }

  #viewCardModal .multibutton button i {
    margin: 0;
  }

  .date_feature {
    margin: 0 0 25px;
  }

  .date_feature button {
    padding: 6px 8px !important;
  }
}

@media (max-width:560px) {
  .main-sec .right-sec.active .loginPaneRight{
    left: -60px !important;
  }
  .main-sec .right-sec .loginPaneRight{
    left: -65px !important;
    width: 100vw;
    padding: 0 20px;    
  }
}

.middle_row .music-licensing-guide-btn {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  outline: none;
  margin-left: 10px;
}

.modal-content .row.linking_area .middle_row .music-licensing-guide-btn svg {
  width: 20px !important;
  height: 25px;
  fill: $theme-color;
}

.chat-annotation-label {
  width: 62%;
  max-width: 85%;
  margin: 0 auto;
  position: absolute;
  top: 9px;
  right: 0;
  text-align: center;
}

.chat-annotation-label span {
  display: inline-block;
  float: none;
  vertical-align: middle;
  font-size: 14px !important;
  margin: 0 5px;
  color: #000 !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.row.chatbox .divider {
  background-color: transparent;
}

#video_upload {
  width: 100%;
  margin: auto;
  padding: 0;
}

#video_upload button.btn.uploadbtn.submit_btn {
  margin-left: 0 !important;
  margin-right: 15px !important;
}

#video_upload .input-field.col label {
  left: 0;
  margin: 2px 0 0;
}

#video_upload .input-field.col {
  margin: 25px 0 17px;
}

#video_upload .highlight thead {
  background: #cccccc;
}

#video_upload .highlight tbody td {
  padding: 16px 15px;
}
#video_upload .highlight{
  width: 95% !important;
  margin: auto !important;
  border: 1px solid #cbbfbf
 }
#video_upload .highlight thead th {
  padding: 16px 15px;
}

.text-copy-btn {
  margin-left: 5px;
}

.after_upload {
  display: flex;
  justify-content: space-between;
  margin: 22px 0 13px;
  padding: 0 !important;
}

#video_upload strong.blue-text {
  color: #444 !important;
}

.chat-select .css-1pahdxg-control:hover,
.chat-select .css-1pahdxg-control {
  background: $theme-color !important;
  border: 1px solid transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none !important;
}

.team-chat-select .css-yk16xz-control,
.team-chat-select .css-1pahdxg-control:hover,
.team-chat-select .css-1pahdxg-control {
  background: #82b150 !important;
  border: 1px solid transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none !important;
}

.team-chat-select .css-1uccc91-singleValue {
  color: #fff;
  border-color: transparent;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  border: 1px solid transparent;
}

.radio-cover {
  text-align: center;
}
.radio-cover .radio  
{
  display:inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.radio-cover .radio label span 
{
  font-weight:bold;
}
.radio-cover .radio:last-child
{
  margin-right:-4px;
}

.chat-cover .inside-toggle:before {
    content: '\2807';
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 10px;
}
.chat-cover .inside-tog-drpdwn {
  position: absolute;
  right: 0;
  width: 100px;
  top: 100%;
  margin: 30px 10px 0 0;
  z-index:999;
  background-color: #f1f1f1;;
  text-align: left;
  border-radius: 2px;;
  box-shadow: 1px 1px 5px #969696;

}
.chat-cover .inside-tog-drpdwn li{padding: 3px 8px; 
  cursor: pointer;}
.chat-cover .inside-tog-drpdwn a{color: #000; display: block;}

.chat-cover .row.common_child_chat {
  background: #f5f5f5;
  padding: 11px;
  border: 1px solid #ccc;
  margin: 8px 11px 0 12px !important;
  width: 98%;
  position: relative;
  float: left;
}

.chat-cover .chat-child-option-btn {
  position: relative;
}

.chat-cover .submit_reply_chat button.btn {
  margin-right: 5px;
  margin-bottom: 9px;
}

.chat-cover .annotation-circle {
  position: absolute;
  top: 16px;
  right: 25px;
}
.chat-cover .main-annotation-circle {
  right: 21px;
}
.chat-cover .mark-comment {
  position: absolute;
  right: 45px;
  top: 26px;
}

.input-field > label {
  color: #555555;
}

label {
  color: #555555;
}

.customer-reload-btn span.text-primary
{
  color:#fff;
}
.customers-row-cards
{
  padding:30px 15px 30px !important;
}
.filter-customer-top 
{
  padding:0 24px 5px !important;
}
.filter-customer-top .input-field .dropdown-trigger
{
    border-color: transparent;
    background-color: rgba(245, 248, 250, 0.8);
    color: #6c7293;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding:0 15px;
    margin-top:20px;
}
.filter-customer-top label 
{
  font-size:15px;
}

.filter-customer-top .users-top-btn
{
  border-bottom:1px solid #eee;
}
.client-dashboard-table
{
  background-color: #fff;
  border-radius:6px;
  -webkit-border-radius:6px; 
  -moz-border-radius:6px; 
  -ms-border-radius:6px;
  padding:5px;
}
.client-dashboard-table tr th, .client-dashboard-table tr td
{
  padding:15px 15px;
}
.show-error {
  padding: 10px;
  margin: 10px;
  color: #ba3939;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
}
.error-hidden {
  display: none;
}


.col.profile-note-col {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 15px 0 15px 0;
}

.profile-note-col strong 
{
  color: $theme-color !important;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-right: 5px;
}
.profile-note-col span
{
  color: #000 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

div#photoPreference-error-msg {
  width: 100%;
  height: 45px;
  margin-top: 93px;
}

.yt-photo-preference-div {
  width: 50%;
  float: right;
}

.yt-photo-preference-div .input-field.col {
  width: 100%;
}

div#descriptionLink-error-msg {
  height: 50px;
  margin-top: 89px;
}

.customer-reload-btn span.text-primary
{
  color:#fff;
}
.customers-row-cards
{
  padding:30px 15px 30px !important;
}
.filter-customer-top 
{
  padding:0 24px 5px !important;
}
.filter-customer-top .input-field .dropdown-trigger
{
    border-color: transparent;
    background-color: rgba(245, 248, 250, 0.8);
    color: #6c7293;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding:0 15px;
    margin-top:20px;
}
.filter-customer-top label 
{
  font-size:15px;
}
button.btn.clear-filter-btn{
  background-color:$theme-color;
}
.archived-cus-button, button.archived-cus-button:focus {
  margin-left: auto;
  float: right;
  height: 50px;
  display: flex !important;
  align-items: center;
  background-color:$theme-color;
  color: #fff;
  border: none;
  box-shadow: none;
  padding: 0 30px;
  gap: 20px;
  border-radius: 5px;
  font-size: 20px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}
.archived-cus-select #react-select-3-input, .archived-cus-select-input input[type="text"] {
  height: auto;
}

.customer-archive-dropdown {
  width: 300px !important;
  height: 50px !important;
}

.customer-filter-export {
  position: relative;
  top:40px;
  min-width: fit-content;
}
#csv-link {
  color: white;
  padding: inherit;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: .5px;
}
.filter-customer-top .users-top-btn
{
  border-bottom:1px solid #eee;
}
.client-dashboard-table
{
  background-color: #fff;
  border-radius:6px;
  -webkit-border-radius:6px; 
  -moz-border-radius:6px; 
  -ms-border-radius:6px;
  padding:5px;
}
.client-dashboard-table tr th, .client-dashboard-table tr td
{
  padding:15px 15px;
}
.color-green-imp {
  background-color: $color-green !important;
}

.due-date-datetime-picker .react-datetime-picker__wrapper .react-datetime-picker__inputGroup{
  margin-right: 35px;
}
.date_feature.date-datetime-picker-box {
  position: relative;
  margin-right: auto;
  margin-left: 0 !important;
}
.date_feature.date-datetime-picker-box .due-time-message {
  position: relative;
  right: 86px;
}

.email_preferences_input span {
  margin-left: 20px;
}

.month-card-created-cardpanel {
  margin: 0; 
  border-radius: 8px;
  height: 49px;
  padding-top: 15px !important;
}

.chat-header-row{
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0px;
}
.text-area_chat .input-field textarea{
  min-height: 70px;
}
.video-Section-row-box .row.chatbox{
  top: -40px
}
.video-Section-row-box{
  margin-bottom: 20px;
  margin-top: 30px;
}
.chat-select-video, .archived-cus-select{
  height: 40px;
  position: relative;
  z-index: 5;
}
.archived-cus-select #react-select-3-input{
  height: auto;
}
.archived-cus-select{
  margin-top: 15px;
}
.chat-select-video [class*="css-"], .archived-cus-select [class*="css-"]{
  height: auto;
}
.chat-select-video.client-type-select [class*="css-"] svg{
  fill: #FFF;
}
.full-chat-box .chat-select-video{
  height: 50px;
}

.profile_outer_video_row .col.s12 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.profile_outer_video_row #profile-portal-vid {
  width: 69%; 
  max-height: 50vh;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
}

.video-prev-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.video-prev-col .video_heading {
  display: inline;
  margin-bottom: 0;
  margin-right: 20px;
}
.video-prev-col .input-field {
  max-width: 150px;
}
.video-prev-col .select-wrapper{
  display: flex;
  align-items: center;
}

.tab-vid-inn {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 28px;
  cursor: pointer;
}

.tab-vid-inn.active {
  background: #bfd9e657;
  font-weight: 600;
  border-radius: 30px;
  color: #49bcf5;
}
.tab-vid {
  margin: 0;
  padding: 20px;
  border-radius: 40px;
  box-shadow: 3px 3px 9px #bfd9e647;
  margin-bottom: 40px;
}

.tab-vid .radio-cover {
  display: flex;
  justify-content: space-around;
}

.tab-vid-inn {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 28px;
  cursor: pointer;
}

.standard-btn {
  height: unset !important;
  width: unset !important;
  padding: 12px 25px !important;
  line-height: 18px !important;
  border-radius: 5px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  margin: 0 !important;
}
.deleted-user {
  color:  $warning-color !important;
  text-decoration: line-through;
}

.tab-vid-inn.active {
  background: #bfd9e657;
  font-weight: 600;
  border-radius: 30px;
  color: #49bcf5;
}
.sideBaruser{
  width: auto;
  display: inline-block;
}
.uerloggedin .notification-nav{
  color: #000;
}
#notificationDropdown{
  width: 250px !important;
  box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.10);
}

.login-main-container.snappyAds {
  min-height: 100vh;
  background: var(--theme--Color);
  h5{
    color: #fff !important;
  }
  .outer-login-container{
    background-color: #fff;
  }
  .inner-login-container h4{
    color: #000;
  }
  .login-button.round-button{
    background-color:$theme-color;
  }
  [type="checkbox"]:checked + span:not(.lever):before {
    border-right: 2px solid var(--theme--Color);
    border-bottom: 2px solid var(--theme--Color);
  }
  .password-reset{
    color: #000;
  }
}
.snappyads  {
  [type="checkbox"]:checked + span:not(.lever):before {
    border-right: 2px solid var(--theme--Color) !important;
    border-bottom: 2px solid var(--theme--Color) !important;
  }
}

/*** Global css ***/
.standard-btn {
  height: unset !important;
  width: unset !important;
  padding: 12px 25px !important;
  line-height: 18px !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  margin: 0 !important;
}

.standard-btn-normal-padding {
  padding: 5px 12px !important;
}

.change-password-btn {
  background-color: $theme-color !important;
  color: $primary-text-color !important;
}

.standard-icon-Container{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $theme-color;
  border-radius: 100%;
  width: 38px;
  height: 38px;
}

svg.standard-icon {
  color: $primary-text-color;
  width: 16px;
  height: 16px;
}

.standard-icon-text {
    position: absolute;
    color: black;
    font-size: 20px; 
    font-weight: bold;
}
