
$color-column-header-bg: #A3A3A3;
$color-video-request-label: $color-red;
$color-on-hold-label: $color-red;
$color-editing-label: #F4CE46;
$color-qa-label: #faa64b;
$color-done-label: $color-green;
$color-repurpose-label: #800080;
$color-canceled-label: $color-column-header-bg;
$theme-color: var(--theme--Color);
$primary-text-color: var(--primary-text--Color);

.viewAsPanel .input-field {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.viewAsPanel {
  margin: 0;
  padding: 0;
}

.toggle-panel {
  margin: 0;
  padding-bottom: 1.3rem;
  border-radius: 8px;
}
.d-inline-flex {
  display: inline-flex !important;
}
.text-align-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.m-0 {
  margin: 0;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pt-20 {
  padding-top: 20px;
}

i.ant-spin-dot-item {
  background-color: $theme-color !important;
}

.searchedUser input {
  border-bottom: none !important;
}

.select-view-as {
  border-radius: 8px;
}
.select-view-as .css-yk16xz-control {
  border-radius: 8px;
}

.cards-panel .collapsible {
  border-radius: 8px 8px 6px 6px;
  border: none;
}

.notification-collapsible .collapsible-body {
  min-height: fit-content;
  height: 100%;
  max-height: fit-content;
  padding: 0;
  border: none;
  box-shadow: none;
}

.cardList .collapsible-body {
  min-height: 10vh;
  height: 100%;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 0;
  border: none;
  box-shadow: none;
}

#video_upload {
  width: 90%;
  margin: auto;
  padding: 80px 0;
}
#video_upload .top-bar-left, #video_upload .top-bar-right, #user_cards .top-bar-left, #user_cards .top-bar-right, 
.all-customer-topbar .top-bar-left, .all-customer-topbar .top-bar-right, .archived-main .top-bar-left, .archived-main .top-bar-right,
.settings-main .top-bar-left, .settings-main .top-bar-right
{
  width:50%;
}
#video_upload  button.btn.uploadbtn.submit_btn {
  margin-left: 0 !important;
  margin-right: 15px !important;
}

#video_upload .input-field.col label {
  left: 0;
  margin: 2px 0 0;
}

#video_upload .input-field.col {
  margin: 25px 0 17px;
}
#video_upload .highlight thead {
  background: #cccccc;
}

#video_upload .highlight tbody td {
  padding: 16px 15px;
}
#video_upload .highlight thead th {
  padding: 16px 15px;
}

.text-copy-btn{
  margin-left: 5px;
}

.after_upload {
  display: flex;
  justify-content: space-between;
  margin: 22px 0 13px;
  padding: 0 !important;
}
#video_upload strong.blue-text {
  color: #444 !important;
}

.downloadTextFile {
  background-color: $theme-color;
  padding: 8px 18px;
  cursor: pointer;
}

a.downloadTextFile {
  color: #fff !important;
}

.uploaded-video-link, .no-uploaded-videos-found {
  margin-left: 2.5rem;
}

.notification-collapsible .collapsible-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #faa64b;
  color: white;
}

.cardList .collapsible-header {
  min-width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
}

.customer-top-header {
  padding: 18px 5px 18px;
  .customer-filter-dropdown-section {
    margin: 0px;
  }
  .dateClearAndExport-filter-section {
    align-items: center ;
    display: inline-flex !important;
    .col.cutomer-filter-dropdown.customer-date-picker-filter {
      top: 0px;
      margin-bottom: 0px;
    }
    .col.cutomer-filter-clear-btn {
      display: inline-block !important;
      top: 0px !important;
      margin-bottom: 0px;
    }
    .col.customer-filter-export {
      margin-top: 0px !important;
      display: inline-block !important;
      top: 0px;
    }
  }
}

.aside-top-filters {
  .customer-top-header {
    .pt-20 {
      padding-top: 23px;
    }
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Select SRT file';
  display: inline-block;
  // background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 8px 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.response-rate-card-title {
  cursor: pointer;
  color: blue;
}

.rating-div h2 {
  font-weight: 600;
  color: $theme-color;
}

.rating-div {
  width: max-content;
  margin-left: -20%;
}

.rating-div p {
  font-weight: 600;
  width: max-content;
}

#thumbs-up {
  color: black;
  margin-left: 10px;
  cursor: pointer;
}

#thumbs-up:hover {
  color: red;
}

.user-tabs .tab a {
  color: $theme-color;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.user-tabs .indicator {
  background-color: $theme-color;
  height: 3px;
}

.export-comments-icon {
  background-color: $theme-color;
}

.export-comments-icon:focus {
  background-color: $theme-color;
}

.export-comments-icon:hover {
  background-color: $theme-color !important;
}

#comments-table, #customers-table {
  display: none;
}

.user-tabs .tab a:hover, .tabs .tab a.active {
  background-color: transparent;
  color: $theme-color;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.srtButton {
  background-color: $theme-color;
  padding: 0 14px;
  color: black;
  font-size: small;
}

.btn-container {
  display: flex;
  justify-content: space-between;
}

.btn-customer {
  width: 45%;
  background-color: #609922;
}

.btn-container .btn-customer:hover {
  background-color: #609922;
}

.btn-container .btn-customer:focus {
  background-color: #609922;
}

.btn-team {
  width: 45%;
  background-color: #ab322d;
}

.btn-container .btn-team:hover {
  background-color: #ab322d;
}

.btn-container .btn-team:focus {
  background-color: #ab322d;
}
.left_video video+.chatbox .btn-container .btn{
  font-size: 11px;
  padding: 0 5px;
  width: 48%;
}

.video-requests-list > .cardList > .collapsible-header {
  background-color: $color-video-request-label;
  color: white;
}

.video-requests-list.valetmedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.video-requests-list.blackthaimedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.video-requests-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.hold-list > .cardList > .collapsible-header {
  background-color: $color-on-hold-label;
  color: white;
}

.hold-list.valetmedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.hold-list.blackthaimedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}
.hold-list.snappyAds > .cardList > .collapsible-header {
  background-color: #2aa3f4;
  color: white;
}


.hold-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.editing-list > .cardList > .collapsible-header {
  background-color: $color-editing-label;
  color: white;
}

.editing-list.valetmedia>.cardList>.collapsible-header,
.editing-list.upkeepmedia>.cardList>.collapsible-header {
  background-color: $theme-color;
  color: white;
}

.editing-list.blackthaimedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.editing-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.qa-list > .cardList > .collapsible-header {
  background-color: $color-qa-label;
  color: white;
}

.qa-list.valetmedia > .cardList > .collapsible-header,
.qa-list.upkeepmedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}


.qa-list.blackthaimedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.qa-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.done-list > .cardList > .collapsible-header {
  background-color: $color-done-label;
  color: white;
}

.done-list.valetmedia>.cardList>.collapsible-header,
.done-list.upkeepmedia>.cardList>.collapsible-header {
  background-color: $theme-color;
  color: white;
}

.done-list.blackthaimedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.done-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.canceled-list > .cardList > .collapsible-header {
  background-color: $color-canceled-label;
  color: white;
}

.canceled-list.valetmedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.canceled-list.blackthaimedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.canceled-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.repurpose-list > .cardList > .collapsible-header {
  background-color: $color-repurpose-label;
  color: white;
}

.repurpose-list.valetmedia>.cardList>.collapsible-header,
.repurpose-list.upkeepmedia>.cardList>.collapsible-header {
  background-color: $theme-color;
  color: white;
}

.repurpose-list.blackthaimedia > .cardList > .collapsible-header {
  background-color: $theme-color;
  color: white;
}

.repurpose-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.assigned-card-list > .cardList > .collapsible-header {
  background-color: $color-video-request-label;
  color: white;
}

.assigned-card-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

.in-progress-card-list > .cardList > .collapsible-header {
  background-color: $color-editing-label;
  color: white;
}

.in-progress-card-list.zepedamedia > .cardList > .collapsible-header {
  background-color: #04499A;
  color: white;
}

//for website which has one background color for card header.

.affiliatexroi > .cardList > .collapsible-header {
  background-color: #ffcc00;
  color: black;
}

.acceleratusmedia > .cardList > .collapsible-header {
  background-color: #ED1C24;
  color: white;
}

.vipleadmachine > .cardList > .collapsible-header {
  background-color: #C8A144;
  color: white;
}

.stantonstudios > .cardList > .collapsible-header {
  background-color: #d4af37;
  color: white;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $theme-color;

  &:hover,
  &:focus {
    color: $theme-color;
  }
}

.ant-tabs-tab-btn {
  color: $theme-color;

  &:hover,
  &:focus {
    color: $theme-color;
  }
}

.linkTextStyle,
.linkTextStyle:hover {
  color: inherit;
}

.unseenMessagesCount {
  background: white;
  padding: 1px 7px;
  font-size: 12px;
  color: black;
  border-radius: 20px;
}

.removeInputSearchBoxBorder .ant-picker-input-active input:focus, .removeInputSearchBoxBorder .ant-picker-input input:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

.removeInputSelectBoxBorder input[type="search"]:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

.ant-picker-range {
  border-color: $theme-color !important;
}

.ant-tabs-ink-bar-animated {
  background: $theme-color !important;
}

.request-card-title {
  border-bottom: 1px solid lightgray;
  padding: 24px;
  word-break: break-word;
  white-space: pre-wrap;

  img[src=''] {
    display: none;
  }

  .card-title {
    width: 100%;
    align-items: center;
    .card-title-text {
      margin-right: auto;
      order: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 22px;
      margin-top: 5px;
    }
    .card-icon-container {
      display: flex;
      gap: 5px;
      margin-top: 5px;
      justify-content: flex-end;
    }
  }
}

.request-card .card-image .card-title, .user-card-title.card-image .card-title {
  position: static;
  color: #000;
  padding: 0;
}

.activity-status-btn {
  background-color: $theme-color;
  cursor: pointer;
  width: max-content;
  display: block;
  padding: 6px 12px;
  border-radius: 4px;
  border: 0;
}

.activity-status-btn  {
  strong {
     color: #FFF !important;
     display: flex;
     align-items: center;
     justify-content: center; 
      svg {
        margin-right: 9px;
        font-size: 15px;
      }
  }
}

.row-card-activity {
  background-color: white;
  display: inline-block;
  padding: 14px 0px;
  width: 100%;
  border: 1px solid #dcdcde;
  background-color: '#fff';
  padding: 14px 0;
  border: 1px solid #DCDCDE;
}

.select-view-as-dashboard {
  width: 100%;
  height: 50px;
}

.date-picker-kanban-view  {
  height: 50px;
  border-color: transparent;
  background-color: rgba(245, 248, 250, .8);
  color: #6c7293;
  font-weight: 400;
  border: none;
}

.select-view-as-dashboard .ant-select-selector {
  background: rgba(245, 248, 250, 0.8) !important;
  border: none !important;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.col-card-activity {
  display: flex;
  align-items: center;
  gap: 14px;
}

.card-activity-initials {
  width: 60px;
  height: 60px;
  background-color: bisque;
  border-radius: 100%;
  line-height: 60px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: sans-serif;
}

.empty-card-activity {
  text-align: center;
  min-height: 340px;
  padding: 100px 0;
}

.empty-card-activity p {
  font-size: 1rem;
  font-family: sans-serif;
}

.empty-card-activity img {
  width: 70px;
  height: 70px;
}

.card-activity-secondary-text {
  font-size: 0.9rem;
  font-family: sans-serif;
  color: #646971;
}

.card-activity-primary-text {
  font-size: 1rem;
  font-family: sans-serif;
}

#viewCardModal .modal-content .inside-row svg {
  position: relative;
}

.request-card {
  min-width: 300px;
  border-radius: 6px;
}

.request-card-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.request-card-line .col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-chiclet {
  padding: 24px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
}

.bg-warning {
  background-color: #faa64b;
  color: #fff;
}

.drag-item .is-moving {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.drag-item .is-moved {
  color: #fff;
}

.label-column {
  margin-bottom: 0 !important;
  padding-left: 0;
}

.label-column label {
  font-size: 1rem;
}

.label-column11 {
  margin-bottom: 0 !important;
  padding-left: 0;
  color: black !important;

}

.label-column11 label {
  font-size: 20px;
  color: black !important;
}

.no-comment-label label {
  font-size: 20px;
  color: black !important;
}

#create-card-modal > .modal-content > .col {
  margin: 0 0 2rem;
}
#update-card-modal > .modal-content > .col {
  margin: 0;
}

.labele_title i {
  margin: -8px 5px 0 0;
  float: left;
  font-size: 39px;
}
.labele_title .wrapperClassName {
  float: right;
  width: 93%;
}
.labele_title {
  margin: 17px 0 0 !important;
}
.margin-bottom {
  margin: 16px 0 0 !important;
}
.labele_title .editorClassName.rdw-editor-main {
  min-height: 200px !important;
  background: #eee;
  padding: 1px 12px;
  border: 1px solid #e4e3e3;
}
span.red {
  padding: 8px;
  color: #fff;
  display: inline-block;
}
.subtitle_div {
  margin: 16px 0 13px !important;
}
.video_Response {
  margin: 0 0 14px !important;
}
.popup_checkbox_modal .input-field {
  margin-bottom: 13px !important;
}
.rdw-emoji-modal{right: 0; left: auto !important;}
.add_Btn {
  padding: 0 0 0 14px !important;
}

@media (max-width:767px){
  .labele_title .wrapperClassName {
      width: 83%;
      margin-bottom: 15px;
  }
  #update-card-modal {
    width: 91% !important;
  }
}


/*********** New CSS ***********/
.main-sec .left-sec
{
  padding:0;
}
.main-sec .right-sec
{
  padding-left:265px;
}
.main-sec .right-sec.active
{
  padding-left:60px;
}
.sidebar, .pro-sidebar
{
  width:265px !important;
  position:fixed !important;
  top:0;
  left:0;
  z-index: 999 !important;
  height: 100vh !important;
  overflow: auto;
  background-color: $theme-color;
}
.pro-sidebar > .pro-sidebar-inner 
{
  background-color: $theme-color !important;
}
.pro-sidebar.collapsed 
{
  width:60px !important;
  min-width:60px !important;
}
.pro-sidebar.collapsed .brand-logo
{
  display:none;
}
.pro-sidebar.collapsed .pro-sidebar-content 
{
  padding-top:70px;
}
.pro-sidebar.collapsed .pro-sidebar .pro-menu a i 
{
  position:relative;
  left:-6px;
}
.pro-sidebar.collapsed .right-sec 
{
  padding-left: 100px;
}
.sidebar-toggle 
{
    background-color: $theme-color;
    border-radius: 0;
    position: absolute;
    top: 12px;
    right: 12px;
    box-shadow: none;
    outline: none;
    padding:0 5px;
    height:30px;
}
.sidebar-toggle:hover, .sidebar-toggle:focus 
{
    background-color: $theme-color !important;
    box-shadow: none;
    outline:none;
}
.sidebar-toggle span.bars 
{
  width:22px;
  height:1px;
  background-color:#fff;
  display:block;
  margin:6px 0;
}
nav .brand-logo, .brand-logo
{
  position: inherit;
  text-align: left;
  padding: 30px 34px 20px;
  width: 100%;
  display:block;
}

.logoWrapper {
  margin-top: 10px;
}

nav .brand-logo img.avatar.hide-on-small-only, .brand-logo img.avatar
{
  display: block;
  width:85px;
  height:85px;
  margin:0 auto 15px 40px;
}
.brand-logo .vidchops-logo 
{
  width:135px !important;
  height:auto !important;
}
.refresh-create-btn
{
  padding-left: 0;
}
.refresh-create-btn .btn 
{
  width: 100px;
  height: 35px;
  text-align: center;
  display: inline-block;
  box-shadow: none;
  outline: none;
  background-color: $theme-color;
  margin-right: 8px;
  border-radius: 3px;
  -webkit-border-radius: 3px; 
  -moz-border-radius: 3px; 
  -ms-border-radius: 3px;
}
.refresh-create-btn .btn:hover, .refresh-create-btn .btn:focus 
{
  background-color: $theme-color
;
}
.refresh-create-btn .btn:last-child 
{
  margin-right:-4px;
}
.refresh-create-btn .btn i 
{
  color:#fff;
  font-size:20px;
}
.pro-sidebar .pro-menu 
{
  padding-top:0 !important;
  padding-bottom: 0 !important;
}
nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i
{
  height:inherit;
  line-height:inherit;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item 
{
  display:block !important;
  padding:0 !important;
}
.sidebar ul li, .pro-sidebar .pro-menu .pro-menu-item
{
  display:block;
  width:100%;
}
.sidebar ul li a, .pro-sidebar .pro-menu a
{
  line-height:65px;
  text-align:left;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding:0 20px;
  color: $primary-text-color !important;
}
.sidebar ul li a svg, .pro-sidebar .pro-menu a svg
{
    margin-right: 15px;
    width: 25px !important;
    height: 23px;
    position: relative;
    top: 6px;
}
.profile-image
{
  width:40px !important;
  height:40px !important;
  position: relative;
  border-radius:3px;
  -webkit-border-radius:3px !important;
  -moz-border-radius:3px !important; 
  -ms-border-radius:3px !important;
}
.profile-pic 
{
  display:none;
}
.right 
{
  float: none !important;
}
/********** Start right sec *********/
.aside-right-top-bar 
{
  width: 100%;
  background-color:#fff;
  border-bottom:1px solid #eee;
  position: relative;
  padding:0 30px;
}
.top-bar-left, .top-bar-center, .top-bar-right
{
  width:33.33%;
  display:inline-block;
  vertical-align: middle;
  margin-right:-4px;
}
.top-bar-right 
{
  text-align:right;
}
.top-bar-right nav .nav-wrapper
{
  background-color:$color-white;
}
.top-bar-right nav ul li 
{
  float:none;
  display:inline-block;
  vertical-align:middle;
}
.top-bar-right nav ul a:hover 
{
  background-color: transparent;
}
.top-bar-right nav ul li a.notification-nav
{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color:#737171;
}
.top-bar-right nav ul li ul.dropdown-content
{
  width:146px !important;  
  top:67px !important;
  // left:75% !important;
  transition:inherit;
  border-radius: .475rem;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgba(82,63,105,0.1);
}
.top-bar-right nav ul li #notificationDropdown
{
  width:250px !important;
  top:67px !important;
  left:55% !important;
  transition:inherit;
  min-height:450px;
  max-height: 100%;
  border-radius: .475rem;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgba(82,63,105,0.1);
}
.top-bar-right nav ul li #notificationDropdown li a 
{
  font-size:14px !important;
}
.top-bar-right nav ul li ul.dropdown-content li a, .top-bar-right nav ul li #notificationDropdown li a 
{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  border-bottom:1px solid #eee;
}

.users-top-btn {
  padding: 20px 30px 15px !important;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;

  .addNewUserButton {
    display: flex;
    align-items: center;
  }

  .reloadAllUsersBtn {
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.top-bar-right nav ul li #profileDropdown li a svg 
{
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.top-bar-right .dropdown-content li.divider 
{
  display:none;
}
.top-bar-left .refresh-create-btn
{
  width:100%;
  display:inline-block;
  vertical-align: middle;
  margin-right: -4px;
}
.top-bar-center .input-submit-btn 
{
  width:100%;
  max-width: 85%;
  position: relative;
}
.top-bar-center .input-submit-btn>.btn 
{
  width: 30px;
  height: 47px;
  line-height: 47px;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: none;
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 0;
}
.top-bar-center .input-submit-btn>.btn  svg 
{
  position: relative;
  top:11px;
}
.top-bar-left .top_bar h3
{
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 22px;
  margin:20px 0;
}
.top_bar .input-field input[type="text"] 
{
  width:100%;
  box-shadow:none;
  outline:none;
  background-color:rgba(77, 89, 149, 0.06);
  color:#6c7293;
  font-size:14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  border:1px solid transparent;
  border-radius:40px;
  -webkit-border-radius:40px; 
  -moz-border-radius:40px; 
  -ms-border-radius:40px;
  height:45px;
  line-height:45px;
  padding:0 15px 0 15px;
}
.top_bar .input-field input[type="text"]:focus 
{
  box-shadow:none;
  outline:none;
  border:0;
}
.top_bar .input-field input[type="text"]::-webkit-input-placeholder 
{ 
  color:#6c7293;
}
.top_bar .input-field input[type="text"]::-moz-placeholder 
{ 
  color:#6c7293;
}
.top_bar .input-field input[type="text"]:-ms-input-placeholder 
{ 
  color:#6c7293;
}

.endCardStyle {
  margin-bottom: 0 !important;
}

.align-center {
  text-align: center;
}

.dashboard-top-bar .top-bar-left, .dashboard-top-bar .top-bar-right, .ythelper-dashboard-topbar .top-bar-left, .ythelper-dashboard-topbar .top-bar-right
{
  width:50%;
  display:inline-block;
  vertical-align:middle;
  margin-right: -4px;
}
.ythelper-dashboard-topbar .top-bar-left .input-submit-btn 
{
  width: 100%;
  max-width: 55%;
  position: relative;
}
.ythelper-dashboard-topbar .input-submit-btn .btn
{
    width: 30px;
    height: 47px;
    line-height: 47px;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: none;
    outline: none;
    border: 0;
    background-color: transparent;
    font-size: 0;
}
.ythelper-dashboard-topbar .input-submit-btn .btn svg 
{
  position: relative; 
  top:11px;
}
.users-top-btn 
{
  padding:30px 30px 0;
}
.users-row-cards, .customers-row-cards
{
  padding:0 30px 0;
}
/********* Top Filters ************/
.aside-top-filters 
{
  width:100%;
  background-color:#fff;
  padding:30px 25px 15px;
}
.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav 
{
  box-shadow:none;
}
.card-rows 
{
  padding-left:30px;
  padding-top:30px;
}
.card-rows>.row 
{
  display:flex;
}
.aside-top-filters .card-panel 
{
  padding:12px 10px;
  background-color:rgba(245,248,250,.8);
}
.aside-top-filters .css-yk16xz-control 
{
  border-color:transparent;
  background-color:rgba(245,248,250,.8);
  color:#6c7293;
  font-size:13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.aside-top-filters  .date-filter-col button
{
    border-color: transparent;
    background-color: rgba(245, 248, 250, 0.8);
    color: #6c7293;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 0 11px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    height: 50px;
    line-height: 50px;
}
.aside-top-filters  .date-filter-col button:hover, .aside-top-filters  .date-filter-col button:focus 
{
  box-shadow:none;
  outline:none;
}
.aside-top-filters .css-yk16xz-control:hover, .aside-top-filters .css-yk16xz-control:focus 
{
  border-color:transparent;
}
.aside-top-filters .card-panel, .aside-top-filters .card-panel span
{
  font-size:12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.aside-top-filters .select-view-as .css-yk16xz-control 
{
  height:50px;
}
.aside-top-filters .css-1wa3eu0-placeholder 
{
  position:inherit;
  top:0;
}
.aside-top-filters .css-1okebmr-indicatorSeparator 
{
  display:none;
}
.aside-top-filters .css-tlfecz-indicatorContainer 
{
  position: relative;
  top:-7px;
}
.aside-top-filters .col.real-date .card-panel
{
  padding:15px 12px;
}
.aside-top-filters .col 
{
    padding: 0 4px;
    flex: inherit;
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 8px;
}
.aside-top-filters .col:last-child
{
  margin-bottom: 0;
}
.aside-top-filters [type="checkbox"]:not(.filled-in) 
{
   border:2px solid #a1a5b7;
}
.aside-top-filters .view-input-col
{
  width:200px;
}
.aside-top-filters .date-filter-col ul  
{
  box-shadow:0 0 5px #eee;
  border-radius:8px;
  -webkit-border-radius:8px; 
  -moz-border-radius:8px;
  -ms-border-radius:8px;
}
.aside-top-filters .date-filter-col ul li .due-date-card
{
  box-shadow:none;
  border-radius: 0;
}
/********** Upload **********/
.upload-main
{
  width:100%;
  padding:35px 25px;
}
.upload-main .css-yk16xz-control 
{
  border-radius:3px;
  -webkit-border-radius:3px;
  -moz-border-radius:3px; 
  -ms-border-radius:3px;
  box-shadow: none;
  outline:none;
  border:1px solid transparent;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size:14px;
  height:49px;
  line-height:49px;
}
.upload-main .css-yk16xz-control:hover, .upload-main .css-yk16xz-control:focus
{
  box-shadow:none;
  border:0;
  outline:none;
}
.upload-main .css-g1d714-ValueContainer 
{
  height: 49px;
  line-height: 49px;
}
.upload-main .css-1wa3eu0-placeholder 
{
  top:52%;
}
.upload-main label 
{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size:16px;
}
.upload-main .input-field .btn
{
    background-color:$theme-color;
    color:#fff;
}
.upload-main .input-field 
{
  padding-left:12px;
}
.upload-main .input-field .btn span 
{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size:13px;
}
.upload-video-btns 
{
  padding-left:12px;
}
.upload-video-btns .btn
{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size:13px;
  height:40px;
  line-height:40px;
  background-color:#6c6c6c;
}
/************ ythelper ************/
.ythelper-main 
{
  padding:35px 25px;
}

.ythelper-main .btn 
{
    width: 100px;
    height: 35px;
    text-align: center;
    display: inline-block;
    box-shadow: none;
    outline: none;
    background-color: #fff;
    margin-right: 8px;
    border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
    -moz-border-radius: 2px !important;
    -ms-border-radius: 2px !important;
}
.ythelper-main .btn i 
{
  color: $theme-color;
  font-size: 20px;
  position: relative;
  top:-16px;
}
.ythelper-main .select-view-as .css-yk16xz-control
{
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    box-shadow: none;
    outline: none;
    border: 1px solid transparent;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    height: 49px;
    line-height: 0;
}
.ythelper-main .select-view-as .css-1uccc91-singleValue {
overflow: visible !important;
}
.ythelper-main .css-g1d714-ValueContainer 
{
  height:49px;
}
.ythelper-main .card-panel 
{
  color: #6c7293;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 12px 10px;
  background-color: #fff;
}
.ythelper-main .card-panel span 
{
  color: #6c7293;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

/*************** Modal popup ********/
.modal
{
  width:55%;
  max-height:100%;
  border-radius:4px;
  -webkit-border-radius:4px; 
  -moz-border-radius:4px; 
  -ms-border-radius:4px;
}
.modal-content h4 
{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #181c32;
  margin-bottom: 20px;
}
.modal-content .col label, .modal-content .col label span
{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.modal-content .public-DraftStyleDefault-block
{
  margin:0;
}
.modal-content .DraftEditor-editorContainer 
{
  background-color:#fff;
}
.labele_title .editorClassName.rdw-editor-main 
{
    min-height: 65px !important;
    background: #fff;
    padding: 0;
    border: 1px solid transparent;
}
.modal-content .col input[type="text"]
{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
.modal-content .input-field .col .btn>i, .modal-content .input-field .col i
{
    position: relative;
    left: -4px;
    top: -1px;
}
.modal-content .right-align .btn
{
  margin-right:15px;
}
.edit-btn-col {
  display: flex;
  justify-content: flex-end;
}
.download-btn-vertical-align {
  align-self: center;
}
.modal-content .inside-row .col, .modal-content .inside-row .col .blue-text
{
  color: #000 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

strong.blue-text {
  font-weight: 600 !important;
}

.modal-content .inside-row .col .blue-text 
{
  padding-left:0 !important;
}
.modal-content .inside-row svg, .modal-content .row .col svg
{
    width: 20px;
    height: 20px;
    top:0 !important;
    transform: inherit !important;
}
.modal-content .row .col .blue-text, .modal-content .row.linking_area .col a, .modal-content .row.bottom_row .col>div,
.modal-content .row .editor_text
{
  color: #000 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.modal-content .assigned .left-padding
{
  padding-left: 0 !important;
}
.modal-content .row.assigned 
{
  margin:32px 0 10px !important;
}
.modal-content .row.assigned .col svg
{
    width: 20px !important;
    height: 20px !important;
    margin-right: 9px !important;
    position: relative !important;
    top: 2px !important;
}
.modal-content .row.assigned .css-1kl3554-control
{
   background-color:#fff !important;
   border:0 !important;
   font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.modal-content .row.linking_area
{
  margin:15px 0 15px !important;
}
.modal-content .row.linking_area .col svg
{
  width: 25px !important;
  height: 25px !important;
  margin-right: 9px !important;
  position: relative !important;
  top: -3px !important;
}
.modal-content .row.bottom_row 
{
  margin:12px 0 !important;
}
.modal-content .inside_linkg button
{
  background-color:$theme-color;
  color:#fff;
}
.modal-content .inside_linkg button rect, .modal-content .inside_linkg button line 
{
  fill:#fff;
}
.modal-content button.edit-btn-card-title
{
  background-color:$theme-color;
  margin-top:10px;
}
.modal-content .select_dropdown .basic-single .select__control 
{
  border:1px solid transparent;
  border-radius: 0;
  box-shadow: none;
  outline: none;
}
.modal-content .listing_button1 .input-field input[type="text"]
{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.modal-content .listing_button1 .input-field label
{
  left:0;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.modal-content .btn-side1 button
{
  background-color:$theme-color;
}
.modal-content .listing_button button.edit-done-video
{
  background-color:$theme-color;
}
.chat-cover {
  width:100%;
  max-height: 65vh;
  min-height: 300px;
  overflow-y: scroll;
  position: relative;
}
.chatbox h5
{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color:#000;
}
.chatbox h5 svg
{
    width: 20px !important;
    height: 20px !important;
    margin-right: 9px !important;
}
.chatbox .comment_area .chat_bubble
{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.chatbox .pagination li.active
{
  background-color:$theme-color;
}

.filters-icon{
  position: relative;
}

.chat-select .css-yk16xz-control
{
  background-color:$theme-color;
  color:#fff;
  border-color:transparent;
  border-radius:3px;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.chat-select .css-yk16xz-control:hover 
{
  background-color:$theme-color;
}
.team-chat-select .css-yk16xz-control 
{
  background-color:#82b150;
  color:#fff;
  border-color:transparent;
  border-radius:3px;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.chat-select .css-1uccc91-singleValue
{
  color:#fff;
}
.team-chat-select .css-1uccc91-singleValue 
{
  border-color:none !important;
}
.chat-select .css-1uccc91-singleValue 
{
  color:#fff;
}
.filter-chat-select .css-yk16xz-control
{
  background-color:#fff;
  border-color:transparent;
  border-radius:3px;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.modal-footer 
{
  padding:0 !important;
  right:15px !important;
}
.modal-footer .modal-close, .modal-footer .btn-primary
{
  margin: 6px 8px 0 0 !important;
  background-color: $theme-color
;
  box-shadow: none;
  outline: none;
  border: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  padding: 12px 18px 11px 12px;
  height: 35px;
  line-height: 0;
  color: #fff;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.modal-footer .modal-close i, .modal-footer .btn-primary i
{
  margin-left:0;
  position: relative;
  top:5px;
}

.playVideo {
  cursor: pointer;
}

/*********** Cards panel ***********/
.card-rows .collapsible 
{
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background-color:#fff;
  border-top-left-radius:12px;
  border-top-right-radius:12px;
}
.card-rows .card-content 
{
  border-radius:0;
}
.card-rows .collapsible-header
{
  font-size:16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.card-rows .request-card-title
{
  padding:3px 15px 8px;
}
.card-rows .card-title p
{
  font-size:15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 17px;
}
.card-rows .card-content strong 
{
  font-size:14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color:#000;
}
.card-rows .card-content
{
  padding:15px 15px 0;
}
.card-rows .request-card-line .col svg 
{
    margin-right: 6px;
    width: 20px;
    height: 20px;
    fill: #2d2d2d;
    position: relative;
    top: 6px;
}
.card-rows .request-card-line .col svg path 
{
  fill:#2d2d2d;
}
.card-rows .request-card-line .col
{
  font-size:14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color:#000;
}
/************ Customer Page *********/
.red.darken-1
{
  background-color:$theme-color !important;
}
.right-sec .card 
{
  border-radius:8px;
  -webkit-border-radius:8px; 
  -moz-border-radius:8px; 
  -ms-border-radius:8px;
   box-shadow: 0 0 13px #848282;
}
.right-sec .card-panel span.text-primary, .right-sec .card-panel span.text-accent-1, .right-sec .card-panel span.text-darken-1
{
  font-size:14px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.right-sec .profile-cover 
{
  margin:25px 25px;
}
.right-sec .profile-cover span.card-title
{
  font-size:22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding-left:8px;
}
.profile-cover .profile-box .left-box, .profile-cover .profile-box .right-box
{
  width:15%;
  display:inline-block;
  vertical-align:bottom;
  margin-right: 25px;
}
.profile-cover .profile-box .left-box img 
{
  border-radius:3px;
  -webkit-border-radius:3px; 
  -moz-border-radius:3px;
  -ms-border-radius:3px;
}
.profile-cover .profile-box .left-box button 
{
  margin-bottom:8px;
}
.profile-cover .profile-box .right-box 
{
  margin-right:-4px;
  width:82%;
}
.profile-cover .card-action button.waves-effect
{
  width:18% !important;
  border-radius:5px !important;
  font-size:14px;
  font-family: 'Poppins', sans-serif;
  font-weight:400;
  color:#fff;
}
.right-sec .user-card-title
{
  padding:15px;
}
.right-sec .card .card-content 
{
  padding:15px;
}
.right-sec .card .card-title a
{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
}
.right-sec .card-content , .right-sec .card-content a 
{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
}
// .right-sec .card-content svg 
// {
//   margin-right: 5px;
//   width: 20px;
//   height: 20px;
//   position: relative;
//   top: 4px;
// }

.custom-input-profile .input-field {
  min-height: 5rem!important;
}

.right-sec .card-content>.row 
{
  margin-bottom:12px !important;
}
.right-sec .card-action
{
  padding:15px 15px;
  border-bottom-left-radius: 8px !important;
  -webkit-border-bottom-left-radius: 8px !important; 
  -moz-border-bottom-left-radius: 8px !important; 
  -ms-border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  -webkit-border-bottom-right-radius: 8px !important; 
  -moz-border-bottom-right-radius: 8px !important; 
  -ms-border-bottom-right-radius: 8px !important;
}
.right-sec .card-action .btn 
{
  margin-right: 8px;
  display: inline-block;
  width: 47% !important;
  height: 45px;
  line-height: 0;
  padding: 0 0 5px;
  background-color: $theme-color;
}

.user-btns-container button {
  min-width: 47% !important;
}

.user-btns-container i.material-icons.right {
  position: relative !important;
}

.right-sec .card-action .btn:hover 
{
  box-shadow: none;
  outline:none;
  background-color: $theme-color;
}
.right-sec .card-action .btn i 
{
  position:relative;
  top:4px;
}
.right-sec .card-action .btn:last-child 
{
  margin-right:-4px;
}
/*********** Archived page ***********/
.archived-main .card-title h3
{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #000;
}
.archived-main .card 
{
  margin:25px 35px;
}
.archived-main .card-content 
{
  background-color:#fff;
  border-radius:8px;
  -webkit-border-radius:8px;
  -moz-border-radius:8px; 
  -ms-border-radius:8px;
 
}
.archived-main .card-content table tr th 
{
    padding: 10px 0 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.archived-main .card-content table tr td button.btn-flat
{
  color: $theme-color
;
}
.archived-main .card-content table tr td button.btn-flat:hover 
{
  background-color:transparent;
}
.archived-main .pagination li.active 
{
  background-color: $theme-color;
}
.archived-main .card button.btn-large 
{
  width:13% !important;
  color:#fff;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align:left;
}
.archived-main .card button.btn-large i 
{
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 19px;
}
/************ Portal Settings ***********/
.settings-main .card-content span.card-title
{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #000;
  margin-bottom:25px;
}
.settings-main .input-field.col label 
{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.settings-main .input-field input[type="text"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #9e9e9e;
  font-size:14px;
}
.settings-main .input-field input[type="text"]::-moz-placeholder { /* Firefox 19+ */
  color: #9e9e9e;
  font-size:14px;
}
.settings-main .input-field input[type="text"]::-ms-input-placeholder { /* IE 10+ */
  color: #9e9e9e;
  font-size:14px;
}
.settings-main .card-action .btn
{
  width: 13% !important;
  color: #fff;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding:0;
}
.settings-main .card-action .btn i 
{
  top:0;
  left:15px;
}
/******** Settings page *********/
.settings-main .card
{
    border-radius: 0 !important;
    box-shadow: none !important;
    margin: 0;
    background-color:#d9d9d9;
    padding:25px;
}
.settings-main .card .card-content 
{
  background-color:#fff;
  border-radius:3px;
  -webkit-border-radius:3px; 
  -moz-border-radius:3px; 
  -ms-border-radius:3px;
}
.settings-main .card-action 
{
  padding:15px 15px 0 0;
}

.response-rate-date-picker {
  padding-top: 15px !important;
}

.customer-filter-datePicker {
  padding-top: 26px !important;
}

.response-rate-date-picker .btn-primary, .response-rate-date-picker .btn-primary:hover {
  background-color: $theme-color;
}

/************ Dashboard page ********/
.dashboard-main-page 
{
  width:100%;
  padding:25px 35px;
}
.dashboard-main-page .card-content button, .customers-filter-main-div .cutomer-filter-dropdown button
{
  background-color:$theme-color;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color:#fff;
}
.dashboard-main-page .card-content table thead tr th 
{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.dashboard-main-page #tab_tabs-110
{
  padding:0 !important;
}
.dashboard-main-page ul.tabs 
{
  border-radius:6px;
  margin-bottom:12px;
}
.dashboard-main-page ul.tabs li a, .dashboard-main-page ul.tabs li a.active
{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color:$theme-color;
}
.dashboard-main-page .tabs .indicator 
{
  background-color: $theme-color;
  height:3px;
}
.dashboard-main-page .card-content span.card-title
{
  font-size: 18px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin-bottom: 18px;
}

.dashboard-main-page .card-content svg
{
    width: 185px;
    height: 185px;
}
.dashboard-main-page .csatTable .card-content svg
{
    width: 20px;
    height: 16px;
}
.dashboard-main-page .dashboard-date-picker .card-content svg
{
  width: 30px;
  height: 15px;
}
.dashboard-main-page .CircularProgressbar .CircularProgressbar-text
{
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.dashboard-main-page table th svg
{
  width:10px !important;
  height:10px !important;
}
#viewCardModal 
{
  transform:none !important;
}
.divider 
{
  margin-bottom:25px;
}
.card-note-col
{
  margin-bottom: 30px;
}
.card-note-col strong 
{
  color: $theme-color !important;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-right: 5px;
}
.card-note-col span
{
  color: #000 !important;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.fileopacitybtn{
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.getfilebutton{
    background: #2bbbad;
    color: #FFF;
    border-radius: 2px;
    height: 50px;
    padding: 0px 15px;
    display: inline-flex;
    float: left;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}
.imagefilename{
  display: none;
}
.rightImageBlock{
  float: right;
  text-align: right;
  width: auto;
  .logo-styling{
    max-width: 100px;
    object-fit: contain;
    width: auto;
  }
}

/************ Mobile Responsive **********/
@media (max-width:1499px)
{
  .top-bar-right nav ul li #notificationDropdown li a 
  {
    font-size:13px !important;
  }
}
@media (max-width:1199px)
{
  .card-rows .col 
  {
    width:100% !important;
  }
  .customers-row-cards .customer-col 
  {
    width:50% !important;
  }
  .archived-main .card-content .col
  {
    width:50% !important;
  }
  .archived-main .card button.btn-large 
  {
    width:20% !important;
  }
  .settings-main .card-action .btn
  {
    width:20% !important;
  }
  .archived-table-row 
  {
    max-width: 100%;
    overflow: auto;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .archived-table-row table 
  {
    min-width: 1200px;
    overflow: scroll;
  }
  .right-sec .card .card-title a 
  {
    font-size:15px;
  }
  .archived-main .card-title h3 
  {
    font-size:18px;
  }
  .archived-table-row table tr td 
  {
    padding:6px 5px !important;
  }
  .settings-main .card-content span.card-title 
  {
    font-size:18px !important;
  }
}
@media only screen and (max-width: 992px)
{
  .hide-on-med-and-down 
  {
      display: block !important;
  }
  .mobile-control-btns 
  {
    display:none;
  }
}
@media (max-width:991px)
{
   .right-sec.hover:before
   {
    content:"";
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 1;
   }
   .right-sec.hover .sidenav-trigger
   {
       display: none;
   }
  .profile-image 
  {
    width:35px !important;
    height:35px !important;
  }
  .top-bar-right nav ul li #notificationDropdown 
  {
    left:0 !important;
  }
  .top-bar-right nav ul li ul.dropdown-content 
  {
    left:45% !important;
  }
  .card-rows 
  {
    padding-left: 15px;
    padding-top: 20px;
  }
  .card-rows > .row 
  {
    display: block;
  }
  .card-rows .mobile-card-rows
  {
    height:290px;
    overflow-y: auto;
  }
  .main-sec .right-sec 
  {
    padding-left: 65px;
  }
  .top-bar-center .input-submit-btn 
  {
    max-width:65%;
  }
  .top_bar .input-field input[type="text"] 
  {
    height:40px;
    line-height: 40px;
  }
  .top-bar-center 
  {
    position: relative;
    left: 35px;
    z-index: 0;
  }
  .card-rows .collapsible-header, .card-rows .card-title p
  {
    font-size:14px;
  }
  .right-sec .card-panel span.text-primary, .right-sec .card-panel span.text-accent-1, .right-sec .card-panel span.text-darken-1 
  {
    font-size:13px !important;
  }
  .archived-main .card-title h3 
  {
    font-size:16px;
  }
  .select-customer-profile .col
  {
    width:35% !important;
  }
  .right-sec .card .card-title a 
  {
    font-size:15px;
  }
  .customers-row-cards .customer-col 
  {
    width:100% !important;
  }
  .ythelper-dashboard-topbar .top-bar-left .input-submit-btn 
  {
    max-width:75%;
  }
  .archived-main .card button.btn-large, .settings-main .card-action .btn
  {
    width:30% !important;
  }
  .archived-table-row table tr td 
  {
    font-size:13px;
  }
  .archived-main .card button.btn-large, .settings-main .card-action .btn, .settings-main .card-action .btn-large, .settings-main .card-action .btn-small
  {
    width:20% !important;
  }
}
@media (max-width:767px)
{
  .aside-right-top-bar 
  {
    padding:15px 25px 0;
  }
  .aside-top-filters 
  {
    padding:18px 20px 5px;
  }
  .top-bar-left, .top-bar-right
  {
    width:50%;
  }
  .top-bar-center 
  {
    width:100%;
    left:0;
  }
  .top-bar-right 
  {
    position: absolute;
    top:8px;
    right: 12px;
  }
  .top-bar-right nav ul li #notificationDropdown li a 
  {
    font-size:12px !important;
  }
  .refresh-create-btn .btn 
  {
    width:80px;
  }
  .refresh-create-btn .btn i 
  {
    font-size:18px;
  }

  .customer-select-sub {
    width: 100%;
    .ant-select-selector {
      padding: 20px 20px !important;
    }
  }
  .aside-top-filters .col 
  {
    display: block;
  }
  .aside-top-filters .view-input-col 
  {
    width:100%;
  }
  .top-bar-left .top_bar h3 
  {
    font-size:16px;
    margin:0 0 15px;
  }
  .select-customer-profile .col 
  {
    width:40% !important;
  }
  .upload-main label 
  {
    font-size:14px;
  }
  .archived-main .card button.btn 
  {
    width:35% !important;
  }
  .settings-main .card-content span.card-title 
  {
    font-size:15px !important;
  }
  .settings-main .input-field.col label 
  {
    font-size:13px;
  }
  nav .sidenav-trigger 
  {
    display:none;
  }
}
@media only screen and (max-width: 600px)
{
  .hide-on-small-only, .hide-on-small-and-down 
  {
      display: inline-block !important;
  }
}
@media (max-width:479px)
{
  .top-bar-right 
  {
    top:17px;
  }
  .top-bar-left 
  {
    position: relative;
    z-index: 1;
  }
  .top-bar-right nav ul li ul.dropdown-content 
  {
      left: 0 !important;
      top: 45px !important;
  }
  .top-bar-right nav ul li ul.dropdown-content li a, .top-bar-right nav ul li #notificationDropdown li a 
  {
    font-size:13px;
  }
  .aside-right-top-bar 
  {
    padding:15px 15px 0;
  }
  .refresh-create-btn .btn 
  {
    width:55px;
    display:inline-block;
    margin-right: 5px;
  }
  .refresh-create-btn .btn i 
  {
    font-size:14px;
  }
  .top-bar-right nav ul li a.notification-nav 
  {
    padding:0;
  }
  .profile-image 
  {
    width: 30px !important;
    height: 30px !important;
    margin: 0 !important;
   }
   .top_bar .input-field input[type="text"] 
   {
     height:35px;
     line-height:35px;
     font-size:13px;
   }
   .top-bar-center .input-submit-btn > .btn svg, .top-bar-center .input-submit-btn > .btn-large svg, .top-bar-center .input-submit-btn > .btn-small svg
   {
     top:7px;
     width:21px;
   }
   .top-bar-right nav ul li #notificationDropdown 
   {
     left: -100% !important;
     top: 47px !important;
   }
}


.common_chat
{
 position: relative;
}
.common_chat .chat-option-btn
{
  position: absolute;
  top:15px;
  right: 5px;
}
.common_chat .chat-option-btn .btn 
{
  background-color:transparent;
  padding:0;
  box-shadow: none;
  outline: none;
}
.common_chat .chat-option-btn .btn svg 
{
  fill:$theme-color;
}

// White Label Customers CSS

.card-container {
  margin-top: 10px;
  min-height: 280px;
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
}

#imgLoginPage, #imgLoginPageYT, #imgSideBar, #imgSideBarProfile {
  margin-top: 18px;
}

.card-headings {
  font-weight: bold;
}

.login-logo-in-card {
  width: 25%;
  height: auto;
  vertical-align: middle;
}

.logo-styling {
  width: 12%;
  height: auto;
  vertical-align: middle;
}

.open-modal-button {
  font-size: 1.1em;
  color: #fff;
  width: 100%;
  margin-top: 8px;
  height: 44px;
  background-color: #82b150;
  box-shadow: none;
}

#txtThemeColor{
  color: rgba(0, 0, 0, 0.42);
}
